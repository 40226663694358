<template>
  <div>
    <patient-profile
      title="Edit Patient Profile"
      method="edit"
      :changed.sync="changed"
    ></patient-profile>
  </div>
</template>

<script>
import PatientProfile from '@/components/patients/PatientProfile.vue'

export default {
  components: { PatientProfile },
  data() {
    return {
      changed: false,
    }
  },
  mounted() {
    if (!this.$route.query.id) {
      this.$router.push({ name: 'list-patients' })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.changed && to.name !== 'login' && to.name !== 'view-patient') {
      this.$root.confirm({
        title: 'Discard?',
        body: 'Are you sure you wish to cancel the patient profile changes',
        cancel: 'No',
        confirm: 'Discard',
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          next()
        }
      })
    } else {
      next()
    }
  },
}
</script>
